var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-container",class:_vm.$route.path === '/' || _vm.$route.path === '/en' ? "dynamic-root" : ("dynamic" + _vm.getCurrentRoute)},[(_vm.page && _vm.page.experience && _vm.page.experience.length)?_c('experienceSection',{attrs:{"experience":_vm.page.experience}}):(
      _vm.page &&
      _vm.page.resources &&
      _vm.page.resources.media &&
      _vm.page.resources.media.length &&
      (_vm.$route.path === '/' || _vm.$route.path === '/en')
    )?_c('heroSection',{attrs:{"hero":_vm.page}}):(
      _vm.page &&
      _vm.page.resources &&
      _vm.page.resources.media &&
      _vm.page.resources.media.length
    )?_c('experienceSectionSecond',{attrs:{"page":_vm.page}}):(_vm.page && _vm.page.highlight && _vm.page.highlight.length)?_c('highlightSecondSection',{attrs:{"highlight":_vm.page.highlight[0]}}):_vm._e(),_vm._v(" "),_vm._l((_vm.content),function(item){return [(
        item.type.split('_').includes('spotdiscoverswiss') &&
        item.type.split('_').includes('show')
      )?[(!item.type.includes('tours'))?[_c('highlightSection',{key:item.id,attrs:{"highlight":item}}),_vm._v(" "),_c('bookingSection',{key:item.id + 'info',attrs:{"booking-obj":item}}),_vm._v(" "),_c('gallerySpotdiscoverswissSection',{key:item.id + 'gallery',attrs:{"gallery":item}}),_vm._v(" "),_c('mapSpotdiscoverswissSection',{key:item.id + 'map',attrs:{"map":item}})]:[(
        item.type === 'spotdiscoverswiss_tours_show' &&
        item.content.data
      )?_c('toursDetail',{key:item.id,attrs:{"tour":item}}):_vm._e()]]:_vm._e(),_vm._v(" "),(item.type === 'text')?_c('textSection',{key:item.id,attrs:{"text":item}}):_vm._e(),_vm._v(" "),(item.type === 'uploads')?_c('uploadsSection',{key:item.id,attrs:{"element":item}}):_vm._e(),_vm._v(" "),(item.type === 'table')?_c('tableSection',{key:item.id,attrs:{"element":item}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotheadless_experiences' &&
        item.content.data.settings.layout === 'list'
      )?_c('listSection',{key:item.id,attrs:{"list":item}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotheadless_experiences' &&
        item.content.data.settings.layout === 'teaser'
      )?_c('twoColumnImageTextSection',{key:item.id,attrs:{"list":item}}):_vm._e(),_vm._v(" "),(item.type === 'textmedia' && item.content.headerLayout === 0)?_c('textmediaSection',{key:item.id,attrs:{"text-media":item}}):_vm._e(),_vm._v(" "),(item.type === 'textmedia' && item.content.headerLayout === 2)?_c('textMapSection',{key:item.id,attrs:{"text-map":item}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotheadless_highlights' &&
        item.content.data.settings.layout === 'teaser'
      )?_c('spotheadlessHighlightsSection',{key:item.id,attrs:{"highlights":item}}):_vm._e(),_vm._v(" "),(item.type === 'spotheadless_infos')?_c('spotheadlessInfosSection',{key:item.id,attrs:{"info":item}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotdiscoverswiss_tours' &&
        item.content.data &&
        item.content.data.settings.layout === 'slider'
      )?_c('toursSection',{key:item.id,attrs:{"tours":item,"experience":_vm.page.experience},on:{"filter":_vm.filter}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotdiscoverswiss_tours' &&
        item.content.data &&
        item.content.data.settings.layout === 'list'
      )?_c('toursListSection',{key:item.id + ' list',attrs:{"list":item,"loading":_vm.isLoading},on:{"pagination":_vm.pagination,"filter":_vm.filter,"advanceFilter":_vm.filterAdvance}}):_vm._e(),_vm._v(" "),(item.type === 'gallery')?_c('gallerySection',{key:item.id,attrs:{"gallery":item}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotdiscoverswiss_all' &&
        item.content.data.settings.layout === 'list'
      )?_c('spotdiscoverswissAllSection',{key:item.id + ' list',attrs:{"list":item,"loading":_vm.isLoading},on:{"pagination":_vm.pagination,"filter":_vm.filter,"advanceFilter":_vm.filterAdvance}}):_vm._e(),_vm._v(" "),(
        item.type === 'spotdiscoverswiss_all' &&
        item.content.data.settings.layout === 'slider'
      )?_c('spotdiscoverswissAllSliderSection',{key:item.id + ' slider',attrs:{"slider":item,"experience":_vm.page.experience},on:{"filter":_vm.filter}}):_vm._e(),_vm._v(" "),(item.type === 'spotheadless_faqs' || item.type === 'accordion')?_c('faqSection',{key:item.id,attrs:{"faq":item}}):_vm._e(),_vm._v(" "),(item.type === 'html')?_c('htmlSection',{key:item.id,attrs:{"bodytext":item.content.bodytext}}):_vm._e(),_vm._v(" "),(item.type === 'form_formframework')?_c('mediaundinfluencerSection',{key:item.id,attrs:{"inputs":item}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }